@use './abstracts/' as *;
@use 'sass:math';

.admin-panel * {
    .row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 math.div($spacing, 2) * -1;

        @include tablet {
            margin: 0 math.div($mobileSpacing, 2) * -1;
        }
    }

    [class*="col-"] {
        padding: 0 math.div($spacing, 2);

        @include tablet {
            padding: 0 math.div($mobileSpacing, 2);
        }
    }

    @for $i from 1 through 12 {
        $v: math.div($i, 12) * 100;
        .col-#{$i} {
            width: $v*1%;
        }
    }

    // medium screen

    @include tablet {
        @for $i from 1 through 12 {
            $v: math.div($i, 12) * 100;
            .col-md-#{$i} {
                width: $v*1%;
            }
        }

        .hide-md {
            display: none;
        }
    }

    // small screen

    @include mobile {
        @for $i from 1 through 12 {
            $v: math.div($i, 12) * 100;
            .col-sm-#{$i} {
                width: $v*1%;
            }
        }

        .hide-sm {
            display: none;
        }
    }
}