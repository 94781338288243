html, body {
    width: inherit !important;
    height: inherit !important;
    opacity: 0.88;
}

.network-err-msg {
    position: relative;
    z-index: 9999;
    background-color: red;
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    h1 {
        color: white;
        font-family: "heavy";
        font-size: 20px;
        padding: 10px 0;
        margin: 0;
    }
    h4 {
        color: white;
        margin: 0;
    }
}