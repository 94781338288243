@font-face {
    font-family: "title";
    src: url("../../assets/fonts/fonts/fa-solid-900.woff2");
}

.ant-picker {
    background: black !important;
    border: 1px solid #593e66 !important;
    margin-bottom: 5px !important;
}

.wrapper {
    /* height: 100%; */
    background: url("../../assets/images/meta-bg.png") 0 0 no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 16px;
}

.ant-picker-input input {
    border: 0px solid !important;
    color: #fff !important;
}

.link input {
    margin-bottom: 5px;
}

.topleft .second {
    padding: 3px;
    margin-left: 10px;
    font-weight: 700;
}

.form-control {
    color: #fff !important;
    text-align: left !important;
}

.line {
    display: flex;
    justify-content: space-between;
}

.sendBtn {
    height: 40px;
}

.w100 {
    width: 100%;
}

._center {
    text-align: center;
}

._right {
    text-align: right;
}

.piece1 {
    max-width: 27%;
    position: fixed;
    top: 0px;
    left: 0px;
}

.piece2 {
    max-width: 27%;
    position: fixed;
    top: 0px;
    right: 0px;
}

.piece4 {
    position: fixed;
    right: 0px;
    max-width: 27%;
    top: 500px;

}

.background img {
    z-index: 0;
}

.topleft {
    // position: absolute;
    z-index: 100;
    left: 30px;
    color: gold;
    padding: 20px;
    margin: 30px;
    border: 1px solid #39125f;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.596);
    width: 400px;
}


.piece3 {
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    position: absolute;
    top: 150px;
    z-index: 1000;
}

.piece3 img {
    width: 220px;
}

.piece5 {
    position: fixed;
    bottom: 0px;
    left: 0;
    max-width: 35%;
}

.pull-right {
    float: right;
}

.login {
    // position: absolute;
    margin-top: 15px;
    float: right;
    top: 8px;
    right: 38px;
    width: 300px;
    z-index: 100;    
}

.login a {
    cursor: pointer;
}

/* .login input { */
input {
    margin-bottom: 10px;
    border: 1px solid #593e66 !important;
    background: #0c0c0cbf;
}

.w100px {
    width: 100px;
}

.main-menu {
    min-height: 108px;
    margin-top: 20px;
    top: 330px;
    z-index: 50;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: "title";
    font-weight: bold;
    text-align: center;
}

.dis-flex {
    display: flex;
}

.main-menu a {
    transition: .3s all ease;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
}

.main-menu a:hover {
    font-size: 52px !important;
}

.menu-left {
    width: 40%;
    display: flex;
}

.main-menu li[name=mission] a {
    color: #f5ff4d;
    font-family: "Bungee Regular", sans-serif;
}

.main-menu li[name=team] a {
    color: #fff;
    font-family: "Bungee Regular", sans-serif;
}

.main-menu li {
    padding: 12px 12px;
    font-size: 48px;
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    cursor: pointer;    
    a {
        position: relative;
        .nav-image {
            margin: 0 auto;
            width: 90%;
            transition: .5s all ease;
        }
    }
    a:hover {
        .nav-image {
            width: 100%;
        }
    }
}

.inline {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;
    margin-top: -21px;
}

a {
    text-decoration: none;
    color: inherit;
}

.menu-right {
    width: 40%;
    display: flex;
}

.main-menu li[name=videos] a {
    color: #51ffb4;
    font-family: "Bungee Regular", sans-serif;
}

.main-menu li[name=shop] a {
    color: #ff4494;
    font-family: "Bungee Regular", sans-serif;
}

.link {
    color: gold;
}

.link a {
    color: gold;
}

.margin-top {
    margin-top: 80px;
}

.btn-main {
    color: #fff !important;
    background-color: #7215d3;
    border-color: #8f31f1 !important;
}

.btn-main:hover,
.btn-main:focus {
    color: #fff !important;
    background-color: #8b31eb !important;
    border-color: #8f31f1 !important;
}

.box-container {
    border: 1px solid #39125f;
    border-radius: 5px;
    padding: 20px;
    background: #0000005c;
    margin-bottom: 20px;
}

.form-control {
    font-size: 15px;
}

.box3 {
    height: 444px;
    overflow-y: scroll;
    padding-bottom: 43px;
}

.box4 {
    height: 247px;
    overflow-y: scroll;
}

.statue {
    margin-bottom: 11px;
    color: gold;
}

.statue .date {
    color: #fff;
    padding: 3px;
}

.message {
    margin-bottom: 11px;
    color: #fff;
}

.message .date {
    color: gold;
    padding: 3px;
}

footer {
    padding: 120px 0;
    position: relative;
    z-index: 9999;
}

footer ul {
    display: flex;
    width: 50%;
    text-align: center;
    justify-content: center;
    color: #fff;
    margin: 32px auto;
}

footer ul li {
    padding: 16px 0;
    margin: 0 4%;
}

footer ul li a {
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    font-size: 32px;
    letter-spacing: 0px;
    transition: .3s all ease;
    font-family: "Bungee Regular", sans-serif;
}

a {
    text-decoration: none;
}

.a:hover {
    text-decoration: none;
    color: inherit !important;
}

.box2 {
    padding: 15px;
    border-radius: 7px;
    background: #ffffff17;
}

.raffle-id-text {
    color: white;
}

.time {
    text-align: center;
}

.time .border {
    max-width: 300px;
    margin: auto;
    text-align: center;
    border-radius: 20px;
    border: 7px solid #1E1329 !important;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1294117647);
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
}

.red {
    color: red!important;
    font-weight: bold;
}

.time img {
    max-width: 150px;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    margin-bottom: 50px;
}

.w50 {
    width: 50%;
}

.raffle-history h4 {
    color: white;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #F7B4FC;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.coinback-left {
    background: url("../../assets/images/left-button.png");
    background-size: 100%;
    width: 101px;
    height: 34px;
    margin-left: 2px;
    margin-right: 2px;
    text-align: center;
    border: none;
    margin-bottom: 5px;
}

.coinback-middle {
    background: url("../../assets/images/middle-button.png");
    background-size: 100%;
    width: 101px;
    height: 34px;
    margin-left: 2px;
    margin-right: 2px;
    text-align: center;
    border: none;
    margin-bottom: 5px;
}

.coinback-right {
    background: url("../../assets/images/right-button.png");
    background-size: 100%;
    width: 101px;
    height: 34px;
    margin-left: 2px;
    margin-right: 2px;
    text-align: center;
    border: none;
    margin-bottom: 5px;
}

.coinback-left:hover, .coinback-right:hover, .coinback-middle:hover {
    background: url("../../assets/images/focus-button.png");
    background-size: 100%;
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    text-align: left !important;
}

.fade-in-animation {
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

// .contentinfo ul {
//     display: flex;
// }

@media screen and (max-width: 992px) {
    .topSection .topleft {
        margin: auto !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .topSection .login {
        float: none !important;
        margin: auto !important;
    }

    .margin-top {
        margin-top: 35px !important;
    }

    .main-menu a {
        font-size: 29px;
    }

    .main-menu a:hover {
        font-size: 32px !important;
    }

    .piece3 {
        top: 245px;
        img {
            width: 150px;
        }
    }

    .topSection .topleft {
        margin-top: 230px !important;
    }
}

@media screen and (max-width: 768px) {
    .links {
        display: grid;
    }

    footer ul {
        display: contents !important;
    }

    .main-menu {
        min-height: 145px;
    }
    .main-menu a {
        font-size: 18px;
    }

    .main-menu a:hover {
        font-size: 20px !important;
    }
}
@media (max-width: 400px) {
    .main-menu li {
        padding: 0px;
    }
    .topleft {
        width: 100%;
        span {
            font-size: 12px;
        }
    }
    .login {
        width: 100%;
    }
    .piece3 {
        top: 300px;        
    }
}

.p0 {
    padding: 0px !important;
}

ul {
    list-style-type: none;
}